import api from 'api';
import OutlineButton from 'components/Buttons/OutlineButton';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import Datepicker from 'components/Datepicker';
import Select from 'components/Select';
import SelectContent from 'components/Select/components/SelectContent';
import SelectTrigger from 'components/Select/components/SelectTrigger';
import useModalHandler from 'hooks/useModalHandler';
import useSelect from 'hooks/useSelect';
import bus from 'modules/bus';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import ProtectedComponent from 'router/components/ProtectedComponent';
import { selectCountries } from 'store/selectors/global.selector';
import toastr from 'toastr';
import enums from 'utilities/enums';
import authEnums from 'utilities/enums/authEnums';
import tooltips from 'utilities/enums/tooltips';
import setClassSuffix from 'utilities/services/ClassManager';
import { EntityContext, PermissionGroupContext } from 'utilities/services/contexts';
import DateManager from 'utilities/services/DateManager';
import IconManager from 'utilities/services/IconManager';
import AssignToUserDropdown from '../../../components/AssignToUserDropdown';
import IssueNumber from '../../../components/IssueNumber';
import ConfirmApprovingEntityModal from '../ConfirmApprovingEntityModal';
import ReportsModal from '../ReportsModal';
import RiskLevelSteps from './components/RiskLevelsSteps';
import './styles.scss';

/**
 * Entity info displayed at the top.
 * @param {object} GeneralEntityInformation - search info which will be displayed
 * @param {func} openModal - opens comment modal
 * @param {func} focusComments - brings the comments into focus
 * @param {func} openSections - Click on issue number will open all sections which have issues and
 * bring first one in focus
 *
 */

const riskLevelFullNames = [
  'Low Risk  (1)',
  'Low Medium Risk  (2)',
  'Medium Risk  (3)',
  'Medium-High Risk  (4)',
  'High Risk  (5)',
];

const GeneralEntityInformation = ({ info, openModal, focusComments, openSections }) => {
  const [country, setCountry] = useState({});
  const [changingKYCRefresh, setChangingKYCRefresh] = useState(false);

  const {
    name,
    countryId,
    status: initialStatus,
    assignedUserId,
    riskLevel,
    kycIssues,
    amlIssues,
    nextReview,
    riskScore,
    riskLevelCalculationMethod,
  } = info;

  const [selectedDate, setSelectedDate] = useState(nextReview || null);
  const { entityId, isNaturalPerson, issues, reloadEntityInformation } = useContext(EntityContext);
  const { edit: canEdit } = useContext(PermissionGroupContext);
  const classBase = 'ickyc-general-entity-info';
  const setSuffix = setClassSuffix(classBase);

  const [statusChanging, setStatusChanging] = useState(false);
  const [entityStatus, setEntityStatus] = useState(initialStatus);
  const { isOpen: reportsModalOpen, open: openReportsModal, close: closeReportsModal } = useModalHandler();
  const { isOpen: isConfirmModalOpen, close: closeConfirmModal, open: openConfirmModal } = useModalHandler();

  const mappedStatuses = useMemo(() => {
    return Object.values(enums.ENTITY_STATUSES).map(status => ({
      id: status,
      label: status,
      value: status,
    }));
  }, []);

  // :TODO use api call
  // Called when changing date for the Next KYC Refre
  const changeKYCRefresh = async date => {
    setSelectedDate(date);
    setChangingKYCRefresh(true);

    try {
      const { data } = await api.kyc.entityManagement.changeNextKYCReviewDate(entityId, DateManager.toBekend(date));
      toastr.success('Next KYC Review Date Successfully Changed.');
      data && bus.broadcastEvent(enums.BUS_EVENTS.NEW_LOG_COMMENT, data);
    } catch (err) {
      toastr.error('Could Not Set Next KYC Review Date');
      console.error(err);
    } finally {
      setChangingKYCRefresh(false);
    }
  };

  const changeStatus = useCallback(
    async status => {
      if (status !== entityStatus && status === 'Approved') {
        openConfirmModal();
        return;
      }
      if (status !== entityStatus) {
        setStatusChanging(true);
        try {
          const { data } = await api.kyc.entityManagement.changeEntityStatus(entityId, { status });
          setEntityStatus(status);
          data.value && bus.broadcastEvent(enums.BUS_EVENTS.NEW_LOG_COMMENT, data.value);

          const {
            data: { riskLevel, score },
          } = await api.kyc.entityManagement.getEntityRiskAndScore(entityId);
          bus.broadcastEvent(enums.BUS_EVENTS.UPDATE_GENERAL_INFO, { riskLevel, riskScore: score ?? 0 });

          toastr.success('Successfully changed entity status.');
        } catch (err) {
          console.error(err);
          toastr.error('Error occurred while changing entity status');
        } finally {
          setStatusChanging(false);
        }
      }
    },
    [entityStatus, entityId],
  );

  const handleApproveStatus = useCallback(
    async values => {
      setStatusChanging(true);
      try {
        const { data } = await api.kyc.entityManagement.changeEntityStatus(entityId, {
          status: 'Approved',
          acceptIssues: Boolean(values?.acceptIssues),
        });
        setEntityStatus('Approved');
        data.value && bus.broadcastEvent(enums.BUS_EVENTS.NEW_LOG_COMMENT, data.value);
        closeConfirmModal();
        if (Boolean(values?.acceptIssues)) {
          reloadEntityInformation(true);
        }

        const {
          data: { riskLevel, score },
        } = await api.kyc.entityManagement.getEntityRiskAndScore(entityId);
        bus.broadcastEvent(enums.BUS_EVENTS.UPDATE_GENERAL_INFO, { riskLevel, riskScore: score ?? 0 });
        toastr.success('Successfully changed entity status.');
      } catch (err) {
        console.error(err);
        toastr.error('Error occurred while changing entity status');
      } finally {
        setStatusChanging(false);
      }
    },

    [entityStatus, entityId],
  );
  const [changignRiskLevel, setChangingRiskLevel] = useState(false);

  const setRiskLevel = async value => {
    setChangingRiskLevel(true);
    await api.kyc
      .setEntityRiskLevel(entityId, value)
      .then(({ data: response }) => {
        bus.broadcastEvent(enums.BUS_EVENTS.UPDATE_GENERAL_INFO, {
          riskLevel: value,
          riskLevelCalculationMethod: 'Manual',
        });
        toastr.success('Successfully set risk level');

        const splitBody = response.body?.split(' risk level ');

        const expandedBody = {
          ...response,
          body: `${splitBody[0]}'s risk level from ${riskLevelFullNames[riskLevel - 1]} ${splitBody[1]}`,
        };

        bus.broadcastEvent(enums.BUS_EVENTS.NEW_LOG_COMMENT, expandedBody);
      })
      .catch(() => {
        toastr.error('Could not set risk level');
      })
      .finally(() => {
        setChangingRiskLevel(false);
      });
  };

  const countries = useSelect(selectCountries);

  useEffect(() => {
    if (countryId) {
      setCountry(countries.find(c => c.id === countryId) || {});
    }
  }, [countries, countryId]);

  const requestPersonalReport = () => {
    openReportsModal();
  };

  return (
    <div className={classBase}>
      <div className={setSuffix('__first')}>
        <h2>{name}</h2>

        <div>
          <span className={`flag-icon flag-icon-${(country.alpha2Code || '').toLowerCase()}`} />
          <b>{country.name}</b>
        </div>

        <Datepicker
          label="Next KYC Review:"
          value={selectedDate}
          onChange={changeKYCRefresh}
          disabled={changingKYCRefresh}
          placeholder="Choose Date"
          preview={!canEdit}
          minDate={new Date()}
          hint={tooltips.KYC_REVIEW}
        />
        <ProtectedComponent
          requiredPermissions={
            isNaturalPerson
              ? [authEnums.PERMISSION_TAGS_MAPPING.naturalPersonReports]
              : [authEnums.PERMISSION_TAGS_MAPPING.legalEntityReports]
          }
          permissionGroup={authEnums.PERMISSION_GROUP.REPORTS}
        >
          <PrimaryButton variant="link" onClick={requestPersonalReport}>
            {IconManager.get(IconManager.names.DOWNLOAD)} Request {isNaturalPerson ? 'Personal' : 'Legal Entity'} Report
          </PrimaryButton>
        </ProtectedComponent>
      </div>

      <div className={setSuffix('__wide')}>
        <div className={setSuffix('__wide__block')}>
          <AssignToUserDropdown
            label="Assigned User"
            hint={tooltips.ASSIGNED_USER}
            assignedUserId={assignedUserId}
            assignUser
            preview={!canEdit}
          />
          <RiskLevelSteps
            active={riskLevel}
            onClick={setRiskLevel}
            changing={changignRiskLevel || !canEdit}
            riskScore={riskScore}
            riskLevelCalculationMethod={riskLevelCalculationMethod}
          />
        </div>
        <div className={setSuffix('__wide__block')}>
          <Select
            value={entityStatus}
            onChange={changeStatus}
            loading={statusChanging}
            Trigger={<SelectTrigger placeholder={entityStatus === 'Pending' ? entityStatus : 'Select Status'} />}
            Content={<SelectContent />}
            label="Status"
            options={mappedStatuses}
            preview={!canEdit}
          />
          <div className={setSuffix('__wide__block__buttons')}>
            <PrimaryButton variant="link" onClick={focusComments}>
              View Comments
            </PrimaryButton>

            <OutlineButton left={IconManager.get(IconManager.names.MESSAGE)} onClick={openModal}>
              Add Comment
            </OutlineButton>
          </div>
        </div>
        <div className={setSuffix('__wide__block')}>
          <IssueNumber kycIssues={kycIssues} amlIssues={amlIssues} onClick={openSections} />
        </div>
      </div>

      <div className={setSuffix('__narrow')}>
        <div className={setSuffix('__narrow__block')}>
          <AssignToUserDropdown
            label="Assigned User"
            hint={tooltips.ASSIGNED_USER}
            assignedUserId={assignedUserId}
            assignUser
          />
          <ProtectedComponent licenceAccessKey={authEnums.ACCESS_BY_LICENCE.RISK_LEVELS}>
            <RiskLevelSteps
              active={riskLevel}
              onClick={setRiskLevel}
              changing={changignRiskLevel}
              riskLevelCalculationMethod={riskLevelCalculationMethod}
            />
          </ProtectedComponent>
        </div>
        <div className={setSuffix('__narrow__block')}>
          <IssueNumber kycIssues={kycIssues} amlIssues={amlIssues} onClick={openSections} />
        </div>
        <div className={setSuffix('__narrow__block')}>
          <Select
            value={entityStatus}
            onChange={changeStatus}
            loading={statusChanging}
            Trigger={<SelectTrigger placeholder={entityStatus === 'Pending' ? entityStatus : 'Select Status'} />}
            Content={<SelectContent />}
            label="Status"
            options={mappedStatuses}
            preview={!canEdit}
          />
          {isConfirmModalOpen && (
            <ConfirmApprovingEntityModal hideModal={closeConfirmModal} onAprove={handleApproveStatus} issues={issues} />
          )}
          <div className={setSuffix('__narrow__block__buttons')}>
            <PrimaryButton variant="link" onClick={focusComments}>
              View Comments
            </PrimaryButton>

            <OutlineButton left={IconManager.get(IconManager.names.MESSAGE)} onClick={openModal}>
              Add Comment
            </OutlineButton>
          </div>
        </div>
      </div>
      {reportsModalOpen && <ReportsModal hideModal={closeReportsModal} entitiesIds={[entityId]} />}
    </div>
  );
};
GeneralEntityInformation.propTypes = {
  info: PropTypes.shape({
    name: PropTypes.string,
    countryId: PropTypes.number,
    stateProvinceId: PropTypes.number,
    assignedUserName: PropTypes.string,
    assignedUserId: PropTypes.string,
    riskLevel: PropTypes.number,
    riskScore: PropTypes.number,
    status: PropTypes.string,
    kycIssues: PropTypes.number,
    amlIssues: PropTypes.number,
    nextReview: PropTypes.instanceOf(Date),
  }),
  openModal: PropTypes.func,
  focusComments: PropTypes.func,
  openSections: PropTypes.func,
};

GeneralEntityInformation.defaultProps = {
  info: {},
  openModal: () => {},
  focusComments: () => {},
  openSections: () => {},
};

export default GeneralEntityInformation;
