import MailOutlineIcon from '@material-ui/icons/MailOutline';
import Checkbox from 'components/Checkbox';
import dayjs from 'dayjs';
import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import toastr from 'toastr';
import api from '../../../../../../../api';
import DeleteButton from '../../../../../../../components/Buttons/DeleteButton';
import OutlineButton from '../../../../../../../components/Buttons/OutlineButton';
import ConfirmationPage from '../../../../../../../components/Modal/ConfirmationPage';
import FormModal from '../../../../../../../components/Modal/FormModal';
import InformationModal from '../../../../../../../components/Modal/InformationModal';
import useModalHandler from '../../../../../../../hooks/useModalHandler';
import utilities from '../../../../../../../utilities';
import setClassSuffix from '../../../../../../../utilities/services/ClassManager';
import DateManager from '../../../../../../../utilities/services/DateManager';
import IconManager from '../../../../../../../utilities/services/IconManager';

const ReportsRow = ({
  rowInfo: { date, type, requestedBy, reportName, status, id, expiryDate, downloadName, format, checked },
  className,
  updateRowData,
  onRemove,
  onCheckboxClick,
  selectedValues,
}) => {
  const [downloading, setDownloading] = useState(false);
  const { close: hideModal } = useModalHandler();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const {
    isOpen: isOpenConfirmDeleteModal,
    open: openConfirmDeleteModal,
    close: closeConfirmDeleteModal,
  } = useModalHandler();

  const downloadRouteAsPromise = useCallback((id, status, type) => {
    return new Promise((resolve, reject) => {
      const downloadFunction =
        type === 'Case'
          ? api.kyc.caseManagement.downloadCaseReport(id, status === 'Expired')
          : api.kyc.entityManagement.downloadReport(id, status === 'Expired');

      downloadFunction.then(response => resolve(response)).catch(error => reject(error));
    });
  }, []);

  const handleDownload = useCallback(
    async (id, status, type, downloadName, format, checked) => {
      setDownloading(true);

      try {
        const { data: responseData, status: resStatus } = await downloadRouteAsPromise(id, status, type);

        if (resStatus === 202) {
          setShowConfirmationModal(true);
          updateRowData('id', id, {
            status: 'Generation in progress',
            expiryDate: dayjs(Date.now())
              .add(7, 'day')
              .toString(),
            checked,
          });
        } else {
          utilities.downloadFile(responseData, `application/${format}`, `${downloadName}.${format}`);
        }
      } catch (err) {
        if (err?.response) {
          const { status: errStatus, data } = err.response;
          if (errStatus === 400) {
            data.text().then(textData => {
              if (JSON.parse(textData).isExpired) setShowConfirmationModal(true);
              else toastr.info(JSON.parse(textData).message);
            });
          }
          if (errStatus >= 404 && errStatus < 500) {
            data.text().then(textData => {
              toastr.error(JSON.parse(textData).message);
            });
          }

          if (status === 500) {
            return { [FORM_ERROR]: data?.message || 'Internal Server Error, Try Again Later' };
          }
        } else return { [FORM_ERROR]: 'Error occured' };
      } finally {
        setDownloading(false);
      }
    },
    [downloadName, downloadRouteAsPromise, format, updateRowData, setShowConfirmationModal],
  );

  const onRemoveIconClick = useCallback(
    e => {
      e.preventDefault();
      openConfirmDeleteModal();
    },
    [openConfirmDeleteModal],
  );

  const onDeleteConfirmation = async () => {
    onRemove(id);
    return api.administration
      .removeReport(id)
      .then(() => {
        onRemove(id);
        toastr.success(`Report ${reportName} deleted`);
        closeConfirmDeleteModal();
      })
      .catch(err => {
        if (err?.response) {
          return { [FORM_ERROR]: 'Error occured' };
        }
      });
  };

  const setColumnSuffix = setClassSuffix(`${className}__column`);

  const downloadCurrentRow = useCallback(() => {
    handleDownload(id, status, type, downloadName, format);
  }, [id, status, type, downloadName, format, handleDownload]);

  const downloadSelectedReports = useCallback(() => {
    selectedValues.forEach(({ id, status, type, downloadName, format }) => {
      handleDownload(id, status, type, downloadName, format, true);
    });
  }, [selectedValues, handleDownload]);

  const handleBachDownload = useCallback(() => {
    if (!selectedValues?.length) {
      downloadCurrentRow();
    } else {
      downloadSelectedReports();
    }
  }, [selectedValues, downloadSelectedReports, downloadCurrentRow]);

  const handleCheckBoxClick = useCallback(() => {
    onCheckboxClick(id);
  }, [id, onCheckboxClick]);

  return (
    <tr key={id} className={`${className}__row`}>
      <td className={setColumnSuffix('__checkbox')}>
        <Checkbox checked={checked} onChange={handleCheckBoxClick} />
      </td>
      <td className={setColumnSuffix('__date')}>
        <span>{date ? DateManager.monDayYearLocal(date) : ''}</span>
      </td>
      <td className={setColumnSuffix('__type')}>
        <span>{type}</span>
      </td>
      <td className={setColumnSuffix('__requestedBy')}>
        <span>{requestedBy}</span>
      </td>
      <td className={setColumnSuffix('__reportName')}>
        <span>{reportName}</span>
      </td>
      <td className={setColumnSuffix('__status')}>
        <span>
          {status}&nbsp;
          {status === 'Ready' && <div>Expires in {DateManager.calculateWhenExpiresInDays(expiryDate)}</div>}
        </span>
      </td>
      <td className={setColumnSuffix('__download')}>
        <OutlineButton
          onClick={handleBachDownload}
          left={IconManager.get(IconManager.names.DOWNLOAD)}
          disabled={downloading || status === 'Generation in progress'}
        >
          {status === 'Expired' || status === 'Not generated' ? 'Generate' : 'Download'}
        </OutlineButton>
      </td>
      <td className={setColumnSuffix('__remove')}>
        <DeleteButton onClick={onRemoveIconClick} />
        <div>
          {isOpenConfirmDeleteModal && (
            <FormModal
              hideModal={closeConfirmDeleteModal}
              title="Remove Report"
              className="ickyc-confirmation-modal"
              onSubmit={onDeleteConfirmation}
            >
              <span className="ickyc-confirmation-message">
                Are you sure you want to delete &nbsp; <h3>{reportName}</h3>
              </span>
            </FormModal>
          )}
        </div>
      </td>

      {showConfirmationModal && (
        <InformationModal hideModal={hideModal} onClick={() => setShowConfirmationModal(false)}>
          <ConfirmationPage
            icon={IconManager.get(IconManager.names.NEWS)}
            title="Preparing Report Download"
            onClick={hideModal}
            icons={<MailOutlineIcon />}
            text={
              <p>
                We are preparing your report <br />
                We’ll send you an email with the link to the file <br />
                when the process is complete
              </p>
            }
          />
        </InformationModal>
      )}
    </tr>
  );
};
ReportsRow.propTypes = {
  rowInfo: PropTypes.shape({
    id: PropTypes.number,
    date: PropTypes.string,
    requestedBy: PropTypes.string,
    type: PropTypes.string,
    reportName: PropTypes.string,
    status: PropTypes.string,
    expiryDate: PropTypes.string,
    downloadName: PropTypes.string,
    format: PropTypes.string,
  }),
  className: PropTypes.string,
  updateRowData: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

ReportsRow.defaultProps = {
  className: '',
  rowInfo: {},
};
export default ReportsRow;
